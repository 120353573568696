<template>
  <v-container
    fluid
  >
    <v-row
      v-if="this.$slots.icon"
      justify="center"
      align="center"
      class="fill-height ma-0"
    >
      <v-icon
        :color="color"
      >
        <slot name="icon"></slot>
      </v-icon>
    </v-row>
    <v-row
      v-if="this.$slots.title"
      justify="center"
      align="center"
      class="fill-height ma-0"
    >
      <div class="title">
        <slot name="title"></slot>
      </div>
    </v-row>
    <v-row
      v-if="this.$slots.description"
      justify="center"
      align="center"
      class="fill-height ma-0"
    >
      <div class="description">
        <slot name="description"></slot>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "EmptyState",
    props: {
      color: {
        type: String,
        default: ""
      }
    }
  }
</script>

<style scoped lang="scss">
  .v-icon {
    font-size: 160px !important;
  }

  .title {
    font-size: 26px;
    font-weight: 500;
    line-height: 40px;
  }

  .description {
    margin: 1em 0;
    font-size: 16px;
    line-height: 24px;
  }
</style>
