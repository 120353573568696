<template>
  <div>
  <v-container
    fluid
    class="fill-height ma-0 "

  >
    <v-row
      justify="center"
      align="center"
      class="fill-height ma-0"
    >
      <empty-state
        v-if="hasError"
        color="error"
        class="text--white"
      >
        <template v-slot:icon>
          mdi-alert
        </template>
        <template v-slot:title>
          Ops! Aconteceu algo estranho...
        </template>
        <template v-slot:description>
          Parece que deu algo de errado com o seu pedido, por favor, tente novamente
        </template>
      </empty-state>
      </v-row>
  </v-container>
    </div>
</template>

<script>
  import {checkOAuthParams} from "../helpers/auth-horus"
  import EmptyState from "../components/Helpers/EmptyState"

  export default {
    name: "EntryPoint",
    components: {EmptyState},
    data: () => ({
      hasError: false,
    }),
    mounted() {
      const finalQuery = this.$route.query

      // Get the action type and remove from the verification of required params
      const actionType = finalQuery["action_type"]
      delete finalQuery["action_type"]
      const redirectPath = actionType === "register" ? "/register" : "/login"

      // Verify if the params are valid
      const isValid = checkOAuthParams(finalQuery)
      if (!isValid) {
        this.hasError = true
        return
      }

      this.$router.push({
        path: redirectPath,
        query: finalQuery,
      })
    }
  }
</script>

<style scoped lang="scss">
  .bg {
    background-image: url("../assets/login/background_image.jpg");
    background-size: cover;
  }
</style>
