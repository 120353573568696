import { render, staticRenderFns } from "./EmptyState.vue?vue&type=template&id=c2957744&scoped=true&"
import script from "./EmptyState.vue?vue&type=script&lang=js&"
export * from "./EmptyState.vue?vue&type=script&lang=js&"
import style0 from "./EmptyState.vue?vue&type=style&index=0&id=c2957744&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2957744",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/horus-auth-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c2957744')) {
      api.createRecord('c2957744', component.options)
    } else {
      api.reload('c2957744', component.options)
    }
    module.hot.accept("./EmptyState.vue?vue&type=template&id=c2957744&scoped=true&", function () {
      api.rerender('c2957744', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Helpers/EmptyState.vue"
export default component.exports