var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "fill-height ma-0 ", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "fill-height ma-0",
              attrs: { justify: "center", align: "center" }
            },
            [
              _vm.hasError
                ? _c("empty-state", {
                    staticClass: "text--white",
                    attrs: { color: "error" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function() {
                            return [_vm._v(" mdi-alert ")]
                          },
                          proxy: true
                        },
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v(" Ops! Aconteceu algo estranho... ")]
                          },
                          proxy: true
                        },
                        {
                          key: "description",
                          fn: function() {
                            return [
                              _vm._v(
                                " Parece que deu algo de errado com o seu pedido, por favor, tente novamente "
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3458908770
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }