var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      this.$slots.icon
        ? _c(
            "v-row",
            {
              staticClass: "fill-height ma-0",
              attrs: { justify: "center", align: "center" }
            },
            [
              _c("v-icon", { attrs: { color: _vm.color } }, [_vm._t("icon")], 2)
            ],
            1
          )
        : _vm._e(),
      this.$slots.title
        ? _c(
            "v-row",
            {
              staticClass: "fill-height ma-0",
              attrs: { justify: "center", align: "center" }
            },
            [_c("div", { staticClass: "title" }, [_vm._t("title")], 2)]
          )
        : _vm._e(),
      this.$slots.description
        ? _c(
            "v-row",
            {
              staticClass: "fill-height ma-0",
              attrs: { justify: "center", align: "center" }
            },
            [
              _c(
                "div",
                { staticClass: "description" },
                [_vm._t("description")],
                2
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }